<template>
  <v-data-iterator :items="prestataires">
    <template v-slot:default="{ items }">
      <v-card @click="setIntervenant(intervenant)" outlined elevation="0" v-for="intervenant in items"
              :key="intervenant.id">
        <div class="pa-3">
          <v-row no-gutters class="pl-3 mb-2">
            <v-col class="pa-0 ma-0 d-flex">
              <h3 class="secondary--text" v-if="hasProfile(intervenant) && hasPrestataire(intervenant)">
                {{ intervenant.profile.prestataire.raison_sociale }}</h3>
              <h3 class="secondary--text" v-else>Non défini</h3>
            </v-col>
            <v-col class="pa-0 ma-0">
              <h3 class="primary--text">{{ intervenant.first_name }} {{ intervenant.last_name }}</h3>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <div v-if="hasProfile(intervenant) && intervenant.profile.metiers !== null">
                <v-chip color="primary" v-for="metier in intervenant.profile.metiers" :key="metier.id">{{
                    metier.name
                  }}
                </v-chip>
              </div>
            </v-col>
            <v-col cols="6">
              <p class="grey--text text--accent-2 pa-0 ma-0"
                 v-if="intervenant.last_intervention_date !== null">Dernière intervention le
                {{ getFormattedDate(intervenant.last_intervention_date) }}</p>
              <p class="grey--text text--accent-2 pa-0 ma-0" v-else>Pas de dernière intervention</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="6" class="pa-0 ma-0">
              <p class="grey--text pa-0 ma-0" v-if="hasProfile(intervenant) && intervenant.profile.tel_fixe !== null">
                {{ intervenant.profile.tel_fixe }}</p>
              <p v-else class="grey--text pa-0 mb-0 mt-2">Pas de téléphone renseigné</p>
            </v-col>
            <v-col class="pa-0 ma-0">
              <p class="grey--text text--accent-2 pa-0 ma-0" v-if="intervenant.last_intervention_note">Note dernière intervention
                : {{ intervenant.last_intervention_note }} / 5</p>
              <p class="grey--text text--accent-2 pa-0 ma-0" v-else>Pas de note pour la dernière intervention</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="6">
              <p class="primary--text pa-0 ma-0" v-if="intervenant.note_globale !== null">Note globale : {{ intervenant.note_globale }} / 5</p>
              <p class="primary--text pa-0 ma-0" v-else>Note globale : Non noté</p>
            </v-col>
            <v-col cols="6">
              <p class="grey--text text--accent-2 pa-0 ma-0" v-if="intervenant.nombre_interventions > 0">{{ intervenant.nombre_interventions }} interventions</p>
              <p class="grey--text text--accent-2 pa-0 ma-0" v-else>{{ intervenant.nombre_interventions }} intervention</p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </template>
  </v-data-iterator>
</template>

<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";

export default {
  name: "PrestataireList",
  mixins: [DateUtilMixin],
  mounted() {
  },
  computed: {
    prestataires() {
      return this.$store.getters["profile/getFilteredPrestataires"];
    }
  },
  methods: {
    setIntervenant(intervenant) {
      this.$store.commit("profile/setSelectedProfile", intervenant);
      const path = `/intervenants/${intervenant.id}`;
      if (this.$route.path !== path) this.$router.push(path);
      this.$vuetify.goTo(0);
    },
    hasProfile(intervenant) {
      return intervenant.profile !== null;
    },
    hasPrestataire(intervenant) {
      return this.hasProfile(intervenant) && intervenant.profile.prestataire !== null;
    },
  },
}
</script>

<style scoped>

</style>