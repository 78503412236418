<template>
  <v-container fluid class="pa-0">
    <v-toolbar>
      <v-slide-group>
        <v-slide-item class="mt-4 mr-2">
          <v-btn v-if="isResponsableOrAdmin || hasPermission('add_profile')" @click="createIntervenant" color="primary">Nouvel intervenant</v-btn>
        </v-slide-item>
        <v-slide-item class="mt-4">
          <v-text-field label="Nom" v-model="search_name"></v-text-field>
        </v-slide-item>
        <v-slide-item class="mt-4 mx-2">
          <v-select label="Métier" v-model="filter_metier" :items="metiers" item-text="name" item-value="id"></v-select>
        </v-slide-item>
        <v-slide-item class="mt-4 mx-2">
          <v-btn @click="removeFilters" text color="red"><v-icon>mdi-trash-can-outline</v-icon> Retirer les filtres</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-row class="ma-0">
      <v-col order-md="1" order="2" cols="12" md="5" id="intervenant-list">
        <v-tabs>
          <v-tab @click="lastTabClicked('intervenant')">
            Intervenant
          </v-tab>

          <v-tab @click="lastTabClicked('intervenant')">
            Prestataires
          </v-tab>
          <v-tab @click="lastTabClicked('prestataire')"></v-tab>
          <v-tabs-slider :color="tabColor"></v-tabs-slider>
          <v-tab-item>
            <PrestataireList/>
          </v-tab-item>
          <v-tab-item>
            <SocieteList/>
          </v-tab-item>
        </v-tabs>

      </v-col>
      <v-col order-md="2" order="1" md="7" cols="12" id="intervenant-view">
        <router-view @edit-intervenant="editIntervenant"></router-view>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import PrestataireList from "@/components/intervenants/PrestataireList";
import NewIntervenantSidebar from "@/components/intervenants/NewIntervenantSidebar";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import SocieteList from "@/views/SocieteList";

export default {
  name: "Intervenants",
  components: {SocieteList, PrestataireList},
  mixins: [AuthMixin, DrawerMixin],
  async mounted() {
    let name = this.$route.query.name;
    if(this.$route.query.new === 'true') {
      this.switchDrawer();
    }
    if (this.$store.getters["profile/profiles"].length === 0) {
      await this.$store.dispatch("profile/fetchProfiles");
    }
    if (this.$store.getters["groups/groups"].length === 0) {
      await this.$store.dispatch("groups/fetchGroups");
    }
    if (this.$store.getters["categories/allCategories"].length === 0) {
      await this.$store.dispatch("categories/fetchCategories");
    }
    if(name !== undefined) {
      this.search_name = name;
    }
  },
  computed: {
    groups_intervenant() {
      return this.$store.getters["groups/groups_intervenant"];
    },
    metiers() {
      return this.$store.getters["categories/metiers"];
    },
    selected_intervenant() {
      return this.$store.getters["profile/getSelectedProfile"];
    },
    search_name: {
      get() {
        return this.$store.getters["profile/getSearchName"];
      },
      set(value) {
        return this.$store.commit("profile/setSearchName", value)
      }
    },
    filter_metier: {
      get() {
        return this.$store.getters["profile/getFilterMetier"];
      },
      set(value) {
        return this.$store.commit("profile/setFilterMetier", value)
      }
    },
  },
  data() {
    return {
      dialog: false,
      tabColor: "primary",
      operation: "create",
      intervenantToUpdate: {},
      intervenant_id: 0,
      selection: "none",
    }
  },
  methods: {
    editIntervenant(intervenant_id) {
      console.log(intervenant_id);
      this.intervenant_id = intervenant_id;
      let properties = {
        groups: this.groups_intervenant,
        operation: "update",
        key: this.intervenant_id,
        intervenantToUpdate: this.selected_intervenant,
      }
      this.openDrawerDialog(NewIntervenantSidebar, properties);
    },
    closeDrawer() {
      this.closeDrawerDialog();
    },
    createIntervenant() {
      let properties = {
        groups: this.groups_intervenant,
        operation: "create",
        key: 0,
        intervenantToUpdate: this.intervenantToUpdate,
      }
      this.openDrawerDialog(NewIntervenantSidebar, properties);
    },
    lastTabClicked(tab) {
      this.tabColor = tab === "intervenant" ? "primary" : "secondary";
    },
    removeFilters() {
      this.search_name = null;
      this.filter_metier = null;
    },
    changeSelection(key) {
      this.selection = key;
    }
  }
}
</script>

