<template>
  <v-data-iterator :items="prestataires">
    <template v-slot:default="{ items }">
      <v-card @click="showPrestataire(prestataire)" outlined elevation="0" v-for="prestataire in items"
              :key="prestataire.id">
        <div class="pa-3">
          <v-row no-gutters class="pl-3 mb-2">
            <v-col class="pa-0 ma-0 d-flex">
              <h3 class="secondary--text" >{{ prestataire.raison_sociale }}</h3>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </template>
  </v-data-iterator>
</template>
<script>
export default {
  name: 'SocieteList',
  async mounted() {
    if(this.$store.getters["prestataires/allPrestataires"]) {
      await this.$store.dispatch("prestataires/fetchPrestataires");
    }
  },
  data() {
    return {};
  },
  computed: {
    prestataires() {
      return this.$store.getters["prestataires/allPrestataires"];
    }
  },
  methods: {
    showPrestataire(prestataire) {
      this.$store.commit("prestataires/setSelectedPrestataire", prestataire);
      const path = `/prestataires/${prestataire.id}`;
      if (this.$route.path !== path) this.$router.push(path);
      this.$vuetify.goTo(0);
    }
  }

}
</script>
